<template>
    <div>
        <block v-if="loading"></block>
        <div>
            <v-tabs v-model="tab">
                <v-tab href="#1">Ver reporte general</v-tab>
                <v-tab href="#2">Ver reporte detallado</v-tab>
                <v-tab href="#3">Ver reporte de abonos</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" touchless>
                <v-tab-item value="1">
                    <v-row style="padding: 1rem 0;">
                        <v-col cols="12">
                            <v-radio-group v-model="search.typeData"  class="ma-3">
                                <v-radio label="Ver reporte con datos de prueba" value="00"></v-radio>
                                <v-radio label="Ver reporte con datos de producción" value="01"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>

                    <!-- <v-row>
                        <h3>Total Adeudado: {{ getGeneralSum() }}</h3>
                    </v-row>
                    <v-row>
                        <h3>Total Pagado: {{ getGeneralPaySum() }}</h3>
                    </v-row> -->

                    <GeneralFilter :filterEndpoint="$API.customers.getAcountsReceivableFiltered" :search="{ search }" @emptyFilter="getAcountsReceivable" @filtered="filterHandler">
                        <DxDataGrid :dataSource="AccountData" :scrolling="{ useNative: true }" :showBorders="true" :column-auto-width="true" @exporting="onExporting">
                            <DxExport :enabled="true" />
                            <DxColumn v-if="$store.getters['rolesUser/getCustomRole'](81) || $store.getters['rolesUser/getCustomRole'](80)" cell-template="show-template" :width="100"/>
                            <DxColumn dataField="ID_Customer" caption="ID Cliente o Negocio" />
                            <DxColumn cell-template="name-customer" caption="Nombre"/>
                            <template #name-customer="{ data }">
                                <div>
                                    {{ data.data.Is_a_business == 1 ? data.data.BusinessName : data.data.FirstName + " " + data.data.LastName }}
                                </div>
                            </template>
                            <DxColumn dataField="LegalName" caption="Nombre legal"/>
                            <DxColumn dataField="TotalCanceledFormatted" caption="Abonado" />
                            <DxColumn dataField="TotalDebtFormatted" caption="Adeudado" />
                            <!-- <DxColumn dataField="TotalDebt" caption="AdeudadoNumero" /> -->
                            <!-- <DxColumn cell-template="total-canceled" caption="Abonado" />
                            <template #total-canceled="{ data }">
                                <div>
                                    {{ Currency + data.data.TotalCanceled.toFixed(2) }}
                                </div>
                            </template>
                            <DxColumn cell-template="total-debt" caption="Adeudado" />
                            <template #total-debt="{ data }">
                                <div>
                                    {{ Currency + data.data.TotalDebt.toFixed(2) }}
                                </div>
                            </template> -->
                            
                            <!--DxColumn dataField="InvoiceCreditDay" caption="Crédito" />
                            <DxColumn cell-template="due-date" caption="Fecha de vencimiento" />
                            <DxColumn cell-template="total-payment" caption="Total" />
                            
                            <template #due-date="{ data }">
                                <div>
                                    {{ calculateDueDate(data.data.InvoiceDate, data.data.InvoiceCreditDay) }}
                                </div>
                            </template>
                
                            <template #total-payment="{ data }">
                                {{ data.data.InvoiceTotalPagar.toFixed(2) }}
                            </template-->
                
                            <template #show-template="{data}">
                                <div>
                                    <!--a class="mx-1">
                                        <v-icon color="primary">mdi-eye</v-icon>
                                    </a-->
                                    <a v-if="$store.getters['rolesUser/getCustomRole'](80)"  class="mx-1" @click.prevent.stop=" addPayment = true; AccountDetails = data.data; key++" title="Realizar abonos a crédito">
                                        <v-icon>mdi-cash-plus</v-icon>
                                    </a>
                                    <a v-if="$store.getters['rolesUser/getCustomRole'](81)" class="mx-1" @click.prevent.stop=" shwoHistory = true; AccountDetails = data.data; key++" title="Historial de abonos y facturas a crédito">
                                        <v-icon>mdi-account-cash</v-icon>
                                    </a>
                                </div>
                            </template>
                
                            
                            <DxPager :showPageSizeSelector="true"  />
                            <DxPaging :defaultPageSize="5" />
                        </DxDatagrid>
                    </GeneralFilter>
                </v-tab-item>
                <v-tab-item value="2">
                    <v-row style="padding: 1rem 0;">
                        <v-col cols="12">
                            <v-radio-group v-model="searchDetailed.typeData"  class="ma-3">
                                <v-radio label="Ver reporte con datos de prueba" value="00"></v-radio>
                                <v-radio label="Ver reporte con datos de producción" value="01"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>

                    <!-- <v-row>
                        <h3>Total Adeudado: {{ getDetailedSum() }}</h3>
                    </v-row>
                    <v-row>
                        <h3>Total Pagado: {{ getDetailedPaySum() }}</h3>
                    </v-row> -->

                    <GeneralFilter :filterEndpoint="$API.customers.getAcountsReceivableDetailed" :search="{ typeData: this.searchDetailed.typeData }" @emptyFilter="getAcountsReceivableDetailed" @filtered="filterHandler2">
                        <DxDataGrid :dataSource="AccountDataDetailed" :scrolling="{ useNative: true }" :showBorders="true" :column-auto-width="true" @exporting="onExporting">
                            <DxExport :enabled="true" />
                            <DxColumn dataField="ID_Customer" caption="ID Cliente o Negocio" />
                            <DxColumn dataField="LegalName" caption="Nombre Legal del Cliente" />
                            <DxColumn dataField="ID_Invoice" caption="ID del documento" />
                            <DxColumn dataField="InvoiceDate" caption="Fecha de Emisión" />
                            <DxColumn dataField="InvoiceTime" caption="Hora de Emisión" />
                            <DxColumn dataField="NumeroControl" caption="Número de Control" />
                            <DxColumn dataField="CodigoGeneracion" caption="Código de Generación" />
                            <DxColumn dataField="PaymentDate" caption="Fecha de vencimiento" />
                            <DxColumn dataField="InvoiceCreditDay" caption="Días de Crédito" />
                            <DxColumn dataField="PaymentWithIvaWithoutNc" caption="Monto" />
                            <DxColumn dataField="Abonado" caption="Abonado" />
                            <DxColumn dataField="PendingToPay" caption="Saldo" />
                            <DxPager :showPageSizeSelector="true"  />
                            <DxPaging :defaultPageSize="5" />
                        </DxDatagrid>
                    </GeneralFilter>
                </v-tab-item>
                <v-tab-item value="3">
                    <v-row style="padding: 1rem 0;">
                        <v-col cols="12">
                            <v-radio-group v-model="searchPaymentsFilter.typeData"  class="ma-3">
                                <v-radio label="Ver reporte con datos de prueba" value="00"></v-radio>
                                <v-radio label="Ver reporte con datos de producción" value="01"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>

                    <!-- <v-row>
                        <h3>Total Pagos: {{ getPaymentSum() }}</h3>
                    </v-row> -->
                    <VersatileFilter 
                        :columnsSearch="columsConfig" 
                        :search="searchPaymentsFilter" 
                        :columnfilterEndpoint="$API.customers.getPaymentsFilteredColumns"
                        :filterEndpoint="$API.customers.getAllPaymentsHistory"
                        :key="reloadPaymentsFilter" 
                        :isAutoScrolled="true"
                        :hideLimitLabel="true"
                        @emptyFilter="getAccountsPaymentHistory" 
                        @filtered="filterPaymentHandler"
                        style="padding: 1rem;"
                    >
                        <DxDataGrid 
                            :dataSource="AccountPaymentData" 
                            :scrolling="{ useNative: true }"
                            :paging="{ enabled: false }"
                            :showBorders="true" 
                            :column-auto-width="true" 
                            @exporting="onExporting"
                            style="max-height: 700px;"
                        >
                            <DxExport :enabled="true" />
                            <DxScrolling mode="virtual"/>
                            <DxLoadPanel :enabled="true"/>
                            <DxColumn dataField="PaymentDate" caption="Fecha de Pago" />
                            <DxColumn dataField="ID_Receipt" caption="ID del Recibo" />
                            <DxColumn dataField="ExtraReference" caption="Referencia de Pago Adicional" />
                            <DxColumn dataField="PaymentType" caption="Tipo de Pago" />
                            <DxColumn dataField="ID_Customer" caption="ID Cliente o Negocio" />
                            <DxColumn dataField="LegalName" caption="Nombre Legal del Cliente" />
                            <DxColumn dataField="FullNameSeller" caption="Vendedor" />
                            <DxColumn dataField="ID_Invoice" caption="ID de la Factura" />
                            <DxColumn dataField="InvoiceDate" caption="Fecha de la Factura" />
                            <DxColumn dataField="CodigoGeneracion" caption="Código de Generación" />
                            <DxColumn dataField="TotalPagar" caption="Monto total" />
                            <DxColumn dataField="SaldoAnterior" caption="Saldo anterior" />
                            <DxColumn dataField="Abono" caption="Monto de abono" />
                            <DxColumn dataField="SaldoActual" caption="Nuevo saldo" />
                            <DxColumn dataField="InvoiceCreditDay" caption="Días" />
                            <!-- <DxPager :showPageSizeSelector="true"  />
                            <DxPaging :defaultPageSize="5" /> -->
                        </DxDatagrid>
                    </VersatileFilter>
                </v-tab-item>
            </v-tabs-items>
        </div>
        

        <v-dialog v-model="addPayment" scrollable :width="$vuetify.breakpoint.smAndDown ? '90%' : '90%'">
            <AccountsReceivableForm
                :envType="searchPaymentsFilter.typeData"
                :key="key"
                :win="win"
                :Customer="AccountDetails"
                v-on:close="reloadData"
            />
        </v-dialog>
        <v-dialog v-model="shwoHistory" scrollable :width="$vuetify.breakpoint.smAndDown ? '90%' : '90%'" ref="arhModal" @hidden="handleModalHiddenHistory">
            <AccountsReceivableHistory
                :key="key"
                :win="win"
                :Customer="AccountDetails"
                :typeData="search.typeData"
            />
        </v-dialog>


    </div>
</template>

<script>
    import Block from "@/components/Block";
    import AccountsReceivableForm from './AccountsReceivableForm.vue';
    import AccountsReceivableHistory from './AccountsReceivableHistory.vue';
    //import {mapState} from 'vuex';
    import {DxDataGrid, DxPager, DxPaging, DxColumn, DxExport, DxScrolling, DxLoadPanel} from 'devextreme-vue/data-grid';
    import GeneralFilter from  "@/components/GeneralFilter";
    import VersatileFilter from  "@/components/VersatileFilter";
    import { printToExcel } from "@/helpers/printToexcel";
    export default {
        name: "AccountsReceivableList",
        components: {
            DxDataGrid,
            DxPager,
            DxPaging,
            DxColumn,
            DxScrolling,
            DxLoadPanel,
            AccountsReceivableForm,
            AccountsReceivableHistory,
            Block,
            GeneralFilter,
            VersatileFilter,
            DxExport
        },
        props: ['win'],
        data() {
            return {
                loading: false,
                AccountData: [],
                AccountDataDetailed: [],
                AccountPaymentData: [],
                panel: 0,
                tab: null,
                key: 0,   
                AccountDetails: {},
                addPayment: false,
                shwoHistory: false,
                Currency: JSON.parse(localStorage.getItem('branch')).Currency,
                search: {
                    typeData: '01',
                },
                searchDetailed: {
                    typeData: '01',
                },
                searchPaymentsFilter: {
                    typeData: '01',
                },
                reloadPaymentsFilter: 0,
                columsConfig: [
                    {
                        name: "LegalName",
                        title: "Nombre Cliente",
                        value: "",
                        type: "text"
                    },
                    {
                        name: "ID_Customer",
                        title: "ID Cliente",
                        value: "",
                        type: "text"
                    },
                    {
                        name: "PaymentDate",
                        title: "Fecha de pago",
                        value: "",
                        type: "text"
                    },
                    {
                        name: "ID_Invoice",
                        title: "ID Factura",
                        value: "",
                        type: "text"
                    },
                ],
            }
        },
        computed:{
          
        },
        mounted() {
            this.search.business = JSON.parse(localStorage.getItem("user")).businessid;
            this.search.branch = JSON.parse(localStorage.getItem("user")).branch;
            this.getAcountsReceivable();
            this.getAcountsReceivableDetailed();
            this.getAccountsPaymentHistory();
        },
        watch: {
            'search.typeData'() {
                this.getAcountsReceivable();
            },
            'searchPaymentsFilter.typeData'() {
                this.getAccountsPaymentHistory();
            },
            'searchDetailed.typeData'() {
                this.getAcountsReceivableDetailed();
            },
        },
        methods: {
            handleModalHiddenHistory(){
                this.$refs.arhModal.destroyChildComponent();
            },
            onExporting(e) {
                printToExcel(e, 'Cuentas por Cobrar');
            },
            getAcountsReceivable(){
                this.loading = true
                this.$API.customers.getAcountsReceivable({typeData: this.search.typeData}).then(response => {
                    this.loading = false
                    this.AccountData = response
                    this.filterId(0)
                    //console.log(this.AccountData);
                })
            },
            getAcountsReceivableDetailed(){
                try {
                    this.loading = true
                    this.$API.customers.getAcountsReceivableDetailed({typeData: this.searchDetailed.typeData}).then(response => {                        
                        this.loading = false
                        this.AccountDataDetailed = response
                        this.filterId(1)
                        //console.log(this.AccountDataDetailed);
                    })
                    
                } catch (error) {
                    console.log( error );
                }
            },
            getAccountsPaymentHistory(){
                try {
                    this.loading = true;
                    this.$API.customers.getAllPaymentsHistory(this.searchPaymentsFilter.typeData).then(res => {
                        this.loading = false;
                        this.AccountPaymentData = res.details;
                        this.filterId(2)
                        //console.log(this.AccountPaymentData);
                    });
                } 
                catch (error) {
                    this.loading = false;
                    console.log(error);
                }
            },
            filterHandler(response) {
                // console.log('recordset filtered', response);
                this.AccountData = response
            },
            filterHandler2(response) {
                this.AccountDataDetailed = response
            },
            filterPaymentHandler(response) {
                this.AccountPaymentData = response;
            },
            reloadData(){
                this.getAcountsReceivable()
                this.addPayment = false
            },
            filterId(type) {
                const id = this.win.meta?.customerToShow.ID_Customer;
                if (id !== undefined) {
                    if (type == 0) {
                        const filteredData = this.AccountData.find(item => item.ID_Customer === id);
                        this.AccountData = filteredData ? [filteredData] : [];
                    }

                    if (type == 1) {
                        const filteredDataDetailed = this.AccountDataDetailed.filter( item => item.ID_Customer === id );
                        this.AccountDataDetailed = filteredDataDetailed;
                    }

                    if (type == 2) {
                        const filteredPaymentData = this.AccountPaymentData.filter( item => item.ID_Customer === id);
                        this.AccountPaymentData = filteredPaymentData
                    }                    
                }
            },
            getDetailedSum(){
                let sum = 0;
                for (const item of this.AccountDataDetailed) {
                    sum += item.PendingToPay;
                }
                return parseFloat(sum).toFixed(2);
            },
            getDetailedPaySum(){
                let sum = 0;
                for (const item of this.AccountDataDetailed) {
                    sum += item['ROUND( ContextM.Canceled, 2)'];
                }
                return parseFloat(sum).toFixed(2);
            },
            getGeneralSum(){
                let sum = 0;
                for (const item of this.AccountData) {
                    sum += item.TotalDebt;
                }
                return parseFloat(sum).toFixed(2);
            },
            getGeneralPaySum(){
                let sum = 0;
                for (const item of this.AccountData) {
                    sum += item.TotalCanceled;
                }
                return parseFloat(sum).toFixed(2);
            },
            getPaymentSum(){
                let sum = 0;
                for (const item of this.AccountPaymentData) {
                    sum += parseFloat(item.Abono.replace('$',''));
                }
                return parseFloat(sum).toFixed(2);
            },
            /* getPaymentRestSum(){
                let sum = 0;
                for (const item of this.AccountPaymentData) {
                    sum += parseFloat(item.SaldoActual.replace('$',''));
                }
                return parseFloat(sum).toFixed(2);
            } */
        },

    }
</script>

<style scoped>

</style>